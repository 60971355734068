<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <c-table 
          :url="'AdminWebService/get_permissions'" 
          :fields="columns" 
          :export="false"
          :delete_has="true"
          :delete_url="'AdminWebService/delete_permission'"
          :edit_has="true"
          :edit_route="'system.permission.edit'"
          :edit_params="{id : 0}"
          hover bordered caption="" 
        ></c-table>  
      </b-col>
    </b-row>
  </div>
</template>

<script>


export default {
  name: 'Permission',
  data: function() {
    return {
      columns: [
        { label: '№', key: 'index', class: 'text-center' },
        { label: 'Нэр', key: 'name', sortable: true, sort_key: "name" },
        { label: 'Код', key: 'code', sortable: true, sort_key: "code" },
        { label: 'Идэвхтэй эсэх', key: 'isActive', sortable: true, sort_key: "is_active", class: 'text-center' },
        { label: 'Тайлбар', key: 'description' },
        { label: 'Хандах эрх', key: 'roleName' },
        { label: 'Үйлдэл', key: 'actions', buttons: [], class: 'text-center'},
      ]
    }
  }
}
</script>
